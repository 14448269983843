//Graph

import React from "react";

const Section7 = () => {
  return (
    <div>
      <h4 style={{ fontSize: "2rem", fontWeight: "650" }}>
        Price Trends for Godrej Garden City vs. Jagatpur
      </h4>
    </div>
  );
};

export default Section7;
