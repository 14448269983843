import React from "react";
import styles from "./styles.module.css";
import Navbar from "../../components/navbar/Navbar.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Section1 from "./Section1.jsx";
import Section2 from "./Section2.jsx";

const index = () => {
  return (
    <div className={styles.maindiv}>
      <div className={styles.section1}>
        <Section1 />
      </div>
      <div className={styles.section2}>
        <Section2 />
      </div>
    </div>
  );
};

export default index;
